@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  background-color: white;
}



/* card component on home page */
.dictionary {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 4rem auto;
  max-width: 90%;
}

.fabbut {
  display: none;/*htis was none*/
}

.gradient-custom {
  /* fallback for old browsers */
  background: #f6d365;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right bottom, rgba(246, 211, 101, 1), rgba(253, 160, 133, 1));

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right bottom, rgba(246, 211, 101, 1), rgba(253, 160, 133, 1))
}

.dictionary>.term {
  text-align: center;
  max-width: 20rem;
  margin: 2rem;
  padding: 2rem;
  border-radius: 20px;
  transition: all 100ms ease-in-out;
  /* background: linear-gradient(147.57deg, #388E3C -213.41%, rgba(255, 255, 255, 0) 91.93%); */
  box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.25);

}

.term {
  background: #ECE9E6;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #FFFFFF, #ECE9E6);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #FFFFFF, #ECE9E6);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 20px 50px 0 rgba(0, 0, 0, 0.1);
  transition: 0.15s ease-in;

  /* Hover and focus state */
  &:hover,
  &:focus-within {
    box-shadow: 0 0 0 2px #1E2A55, 0 10px 60px 0 rgba(0, 0, 0, 0.1);
    transform: translateY(-5px);
  }
  /*color-#1E2A55*/
}

/* Target FaTh (Dashboard) icon to make it outlined */
.dashboard-icon {
  fill: none; /* Remove the default fill */
  stroke: white; /* Add a stroke color to create the outline */
  stroke-width: 20; /* Adjust the thickness of the outline */
}

/* Add hover effect for the outlined icon */
.dashboard-icon:hover {
  stroke: #327E36; /* Optional: Change the stroke color on hover */
}

.home-icon {
  fill: none; /* Remove the default fill */
  stroke: white; /* Add a stroke color to create the outline */
  stroke-width: 20; /* Adjust the thickness of the outline */
}

/* Add hover effect for the outlined icon */
.home-icon:hover {
  stroke: #327E36; /* Optional: Change the stroke color on hover */
}


.top .title {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 43px;
  font-weight: 900;
  color: #27413D;

}

.middle {
  margin-bottom: 0.5rem;
}

.middle .price {
  font-size: 40px;
  font-weight: 600;
  margin-top: -20px;
}

.middle .offer {
  font-size: 30px;
  font-weight: 400;
}

.middle .seeMore {
  font-size: 18px;
  font-weight: 400;
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

.bottom {
  cursor: pointer;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  background-color: #1E2A55;
  position: relative;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 39px;
}

.mob {
  display: flex;
  position: absolute;
  background: blue;
  padding-bottom: 50%;
  font-size: x-large;
  height: 100%;
  font-weight: 500;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.bottom:hover {
  background-color: #327E36;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.link {
  color: white;
}

.active {
  color: greenyellow;
}

/* login styling */
/* .title{ */

/* } */
/* .container {
  perspective: 600px;
}

.box2 {

  transition: 0.5s;
  transform-origin: center;
  margin-top: 20px;
}

.box2:hover {
  transform: rotatey(60deg);
} */

/* Hide the spinner arrows for number inputs of contact us page */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* For Firefox */
}


.container {
  perspective: 600px;

}

.box2 {
  transition: all .5s;
  transform-origin: center;
  margin-top: 20px;
}

.box2:hover {
  transform: translateY(6px);
}

/* login card */
.loginbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 4rem auto;
  /* max-width: 90%; */
  padding: 2rem;
  border-radius: 20px;
  transition: all 100ms ease-in-out;
  background: linear-gradient(147.57deg, #388E3C -213.41%, rgba(255, 255, 255, 0) 91.93%);
  box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.25);
}

.title {
  height: 65px;
  left: 453px;
  top: 2710px;
  border-radius: nullpx;
  font-size: 3rem;
  font-weight: 700;
  line-height: 65px;
  letter-spacing: 0em;
  font-family: 'Segoe UI';
  color: transparent;
  background-size: 400%;
  background-clip: text;
  -webkit-background-clip: text;
  background-image: linear-gradient(-45deg, rgb(76, 156, 58), rgb(23, 26, 226));
  ;
  animation: animasi 3s linear infinite;
}

@keyframes animasi {
  0% {
    background-position: 0 100%;
  }

  50% {
    background-position: 100% 0;
  }

  100% {
    background-position: 0 100%;
  }
}

.abcd {
  display: none; /*this was none*/
}

p {
  text-wrap: wrap;
  word-wrap: break-word;
}



.dictionary {
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  justify-content: center;
  max-width: 90%;
}

.container {
  max-width: 800px;
  margin: 0 auto;
}

.container img {
  max-width: 100%;
  height: auto;
}

.container h2 {
  font-size: 2em;
  margin-top: 20px;
}

.container p {
  font-size: 1.2em;
  line-height: 1.5;
  margin-top: 10px;
}

/* adjust the font size to 800px */

@media only screen and (max-width: 800px) {
  body {
    font-size: 14px;
  }

  .abc {
    display: none; /*this was none*/
  }

  .adc {
    display: table-column;
  }

  .abcd {
    display: block; /*this was bloac*/
  }

  .abcd2 {
    flex-direction: column;
  }

  .abcd3 {
    align-items: center;
    flex-direction: column;
  }

  .resNav {
    display: none; /*this was none*/
  }

  .fabbut {
    display: block; /*this was block*/
    right: 1rem;
    top: 1.25rem;
    position: absolute;
    z-index: 7;
    font-size: 1.5rem
      /* 24px */
    ;
    line-height: 2rem
      /* 32px */
    ;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}

/* Adjust font size for screens smaller than 400px */
@media only screen and (max-width: 400px) {
  body {
    font-size: 12px;

  }

  .arti {
    display: flex;
    flex-direction: column-reverse;
  }

  .fabbut {
    display: block; /*this was block*/
    right: 1rem;
    top: 1.25rem;
    position: absolute;
    font-size: 1.5rem
      /* 24px */
    ;
    line-height: 2rem
      /* 32px */
    ;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .abc {
    display: none;/*this was none*/
  }

  .abcd {
    display: block;/*this was block*/
  }

  .abcd2 {
    flex-direction: column;
  }

  .abcd2 .abcd22 {
    padding-left: 30px;
  }

  .abcd3 {
    align-items: center;
    flex-direction: column;
  }

  .resNav {
    display: none;/*this was none*/
  }

  .dictionary {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 90%;
  }

  .container-card {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .slides-img{
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      }
}

/* Profile details */
.dashboard-main {
  background: #0f0f0f;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #0f0f0f, #0f0f0f); /*color was ECE9E6 changed to black*/
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #0f0f0f, #0f0f0f);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 20px 50px 0 rgba(0, 0, 0, 0.1);
  transition: 0.15s ease-in;

  /* Hover and focus state */
  &:hover,
  &:focus-within {
    box-shadow: 0 0 0 2px #1E2A55, 0 10px 60px 0 rgba(0, 0, 0, 0.1);
    transform: translateY(-5px);
  }
}

.edit-btn {
  margin-top: 18px;
  color: #fff;
  font-size: 18px;
  padding: 2px;
  width: 5rem;
  height: 2rem;
  padding: 2px;
  border: 2px solid #1E2A55;
  border-radius: 10px;
  background-color: #1E2A55;
}

.edit-btn:hover {
  border: 2px solid #327E36;
  background-color: #327E36;
}

.logout-img {
  margin: 5px;
  width: 2rem;
  height: 2rem;
  padding: 2px;
  border-radius: 100%;
  background-color: #1E2A55;
}

.logout-img:hover {
  background-color: #327E36;
}

.field {
  width: 140px;
  background-color: transparent;
  color: white;
  border: 1.5px solid #1E2A55;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  padding: 5px 10px;
  border-radius: 5px;
  transition: color 0.3s, background-color 0.8s;

  &:hover {
    color: #fff;
    background-color: #1E2A55;
  }
}

/* Profile details */
/* edit user */
.edit-user {
  background: #0f0f0f;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #0f0f0f, #0f0f0f); /*ECE9E6*/
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #0f0f0f, #0f0f0f);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 20px 50px 0 rgba(0, 0, 0, 0.1);
  transition: 0.15s ease-in;

}
.field2{
  width: 200px;
  background-color: transparent;
  color: white;
  border: 1.5px solid #1E2A55;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  padding: 5px 10px;
  border-radius: 5px;
  
}

/* edit user */


/*from here i have applied my css here below css for contact us input field floating element*/
/* Container for label and input */
.form-group {
  position: relative;
  margin-top: 4px;
}

/* Styling the input field */
.floating-input {
  padding: 12px 9px 9px;
  font-size: 15px;
  border: 1px solid #ccc;
  outline: none;
  border-radius: 4px;
}

/* Initially place the label over the input */
.floating-label {
  position: absolute;
  left: 10px;
  top: 12px;
  color: #aaa;
  font-size: 15px;
  pointer-events: none;
  transition: all 0.2s ease;
  background-color: white;
  padding: 0 4px;
}

/* Move label to top border when input is focused or has text */
.floating-input:focus + .floating-label,
.floating-input:not(:placeholder-shown) + .floating-label {
  top: -10px;
  font-size: 14px;
  color: #333;
}

/* Hide placeholder when input is focused */
.floating-input:focus::placeholder {
  color: transparent;
}
/*added font size for requestphyicalnotes.js section part input fields*/
.reqphyfont{
  font-size: 17px;
  padding: 9px;
}

/* Media Query for Smaller Devices */
@media (max-width: 600px) {
  .floating-input {
    font-size: 10px; /* Smaller font size for input */
    padding: 10px 12px 6px; /* Adjust padding for smaller screens */
  }
  
  .floating-label {
    font-size: 10px; /* Smaller font size for label */
    top: 10px; /* Adjust position for smaller screens */
  }
  .floating-input:focus + .floating-label,
  .floating-input:not(:placeholder-shown) + .floating-label {
    top: -10px;
    font-size: 10px;
    color: #333;
  }
  .reqphyfont{
    font-size: 14px;
    padding: 12px;
    margin: 3px;
  }
}

@media (max-width: 600px) {
.cross{
  top: 8px;
right: 8px;

}
.fatimeicon{
  font-size: 15px;
}
}

/* styling for scrollable animation in smaller devices*/
/* Modal overlay */
/* Modal base style */
.modal-container {
  max-height: 90vh;
}

/* Scrollable only on smaller screens */
@media (max-width: 768px) {
  .modal-container {
    overflow-y: auto;
  }
}

/* added notes css which of card component */

.main-notes-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px; /* Adjust spacing between sections */
  margin: 20px;
}

.notes-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.notes-card-container {
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
}

.vertical-divider {
  width: 1px;
  height: 350px;
  background-color: rgb(125, 121, 121);
  margin-left: 41px;
}
/* Hide the divider on small and medium screens */
@media (max-width: 768px) {
  .vertical-divider {
    display: none;
  }
}


.notes-card {
  border: 1px solid #ddd;
  border-radius: 8px;

padding: 5px;
padding-bottom: 10px;
  width: 250px;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  
}

.notes-image {
  width: 100%;
  height: auto;
  object-fit: cover;  
  border-radius: 4px;
  margin-bottom: 5px;
  
}

.download-button {
  background-color: rgb(61, 60, 60);
  color: white;
  padding: 8px 16px;
  margin-top: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.download-button:hover {
  background-color: #0056b3;
}

.notes-topheader {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  text-align: center; /* Center text within each line */
  padding: 30px;
  margin-bottom: 20px;
  background-color: #f7f7f7; /* Optional: background color for emphasis */
  color: #333; /* Adjust color for readability */
  font-family: Arial, sans-serif; /* Choose a clean font */
}

.notes-head2{
  font-size: 20px;
  margin-bottom: 18px;
}
.request-notes {
  text-align: center;  /* Centers the content horizontally */
  padding: 30px 20px;  /* Adds padding around the content */
  background-color: #f5f5f5;  /* Light grey background for the section */
  border-radius: 8px;  /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  /* Light shadow for depth */
}

.request-notes h2 {
  font-size: 25px;  /* Larger font size for the title */
  font-weight: semibold;  /* Bold title */
  color: #2c3e50;  /* Dark text color for contrast */
  margin-bottom: 10px;  /* Space below the title */
}

.request-notes p {
  font-size: 18px;  /* Smaller font size for the subtitle */
  color: #7f8c8d;  /* Lighter text color for the subtitle */
  font-style: italic; 
  margin-bottom: 20px;  /* Space below the title */

}



.physical-notes-request-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Full height of the viewport */
  background-color: #f0f0f0; /* Light background color */
}

.physical-notes-box {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 600px;
  max-width: 100%;
  margin-top: 20px;
}

.physical-notes-box h2 {
  text-align: center;
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.physical-notes-box input,
.physical-notes-box textarea,
.physical-notes-box select {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1rem;
}

.physical-notes-box textarea {
  height: 100px;
}

.physical-notes-box button {
  background-color: #4CAF50; /* Green background */
  color: white;
  padding: 12px;
  border: none;
  border-radius: 4px;
  width: 100%;
  font-size: 1.1rem;
  cursor: pointer;
}

.physical-notes-box button:hover {
  background-color: #45a049; /* Darker green */
}

.physical-notes-box select {
  font-size: 1rem;
}
.terms {
  display: flex;
  margin: 2px;
  padding: 2%;

 }
 .terms input{
  transform: scale(1.9);
 }
 .termss{
  font-size: 17px;
padding: 10px;

}
@media (max-width: 768px) {
  
  .terms input{
    transform: scale(1);
   }
   .termss{
    font-size: 13px;
  padding: 2px;
  
  }
}


  